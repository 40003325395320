/* WC 2021 Media Queries */

@media screen and (max-width: 1028px) {
    #kh_n00WC {
        width: 686px;
    }

    #kh_n00WC .rteWC {
        padding: 30px;
    }

    #kh_n00WC .rteWC.rteWC2col {
        column-gap: 30px;
    }

    #kh_n00WC .wcBigVid {
        width: 686px;
        padding: 45px 30px 1px 30px !important;

    }

    .wcBigVid .video {}

    .wcBigVid .video img {
        width: 100%;
    }

    .wcBigVidIn {
        position: relative;
        overflow: hidden;
        padding-bottom: 56.25%;
        margin: 40px auto 30px auto;
        height: 100%;
    }

    d.wcBigVidIn iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }

    div.catAggContainer {
        width: 686px;
        margin-bottom: 0px;
        padding: 30px;
    }

    #kh_n00WC ul.catAggList li {
        width: 33.33% !important;
    }

    #catAge {
        width: 3px;
    }

    div.kh-circles {
        width: 686px;
        padding: 30px;
    }

    div.kh-cc-img img {
        width: 140px;
    }

    div.kh-cc-text {
        padding-left: 26px;
    }

    #kh_n00WC div.kh-gallery {
        width: 686px;
        padding: 30px;
        height: 280px;
    }

    #kh_n00WC .kh-g-inner h2 {
        margin: 0 0 20px 0;
    }

    #kh_n00WC ul.kh-g-content {
        height: 260px;
    }

    #kh_n00WC ul.kh-g-content li a,
    #kh_n00WC ul.kh-g-content li a:link,
    #kh_n00WC ul.kh-g-content li a:visited {
        width: 138px;
    }

    #kh_n00WC div.kh-gallery ul.kh-g-content li {
        display: inline-block !important;
        margin-right: 20px;
    }

    #kh_n00WC .kh-gallery button {
        bottom: 94px;
    }

    .heromaincontainer {
        width: 686px;
        overflow: hidden;
        margin: 0 auto;
    }

    .herorightalign .responsivedesktop {
        margin-left: -108px;
    }

    #kh_n00WC .heroleftalign img.responsivedesktop {
        margin-left: -80px !important;
    }

    .herotextouter {
        top: 45px;
        width: 300px;
    }

    .herorightalign .herotextouter {
        right: 45px;

    }

    .heroleftalign .herotextouter {
        left: 45px;
    }

    div.relatedTopicsContainer {
        width: 686px;
        height: 192px;
    }

    #kh_n00WC div.relatedTopicsContainer h2 {
        margin-top: 0px;
        margin-left: 30px;
    }

    .relatedTopics button {
        bottom: 39px;
    }

    #kh_n00WC div.relatedTopicsContainer.khComponentTitleHiderSR {
        height: 162px;
        padding-top: 56px !important;
    }

    ul.relatedTopicsList li:first-child {
        margin-left: 30px;
    }

    ul.rotator {
        width: 686px;
        margin: 0 auto;
    }

    div.rotatorContainer {
        width: 686px;
        margin: 0 auto;
    }

    .rotator li {
        width: 686px;
    }

    .rotImage {
        width: 300px;
    }

    .rotText,
    .rotator.rotGray .rotText {
        width: 300px;
        padding: 45px 30px 20px 60px !important;
        background-size: 130%;
    }

    #kh_n00WC .rotText h2 {
        line-height: 1.2;
        font-size: 22pt;
        margin-bottom: 20px;
        margin-top: -10px;
    }

    .rotText p {
        font-size: 16px;
    }

    .rotText,
    .rotText a {}

    .rotText a {}

    button.rotPlayPause {
        right: 264px;
    }

    #kh_n00WC .wc3Tiles {
        width: 686px !important;
        column-gap: 28px;
        padding-top: 30px !important;
        padding-bottom: 30px !important;

    }

    #kh_n00WC ul.wc3Tiles.wc2Tiles {
        column-gap: 30px;
        padding: 30px;
    }

    #kh_n00WC ul.wc3Tiles li img {
        awidth: 210px;
    }

    #kh_n00WC ul.wc3Tiles li {
        width: 210px;

    }

    #kh_n00WC ul.wc3Tiles.wc2Tiles li,
    ul.wc3Tiles.wc2Tiles li img {
        width: 293px;
    }

    ul.wc3Tiles li p {
        width: 210px;
        display: block;
    }

    ul.wc3Tiles li p a {
        display: inline;
    }

    ul.wc3Tiles.wc2Tiles li p {
        width: 293px;
    }

}

/* end 1028 */

@media screen and (max-width: 1012px) {
    .rotImage.rotImageMid {
        width: 686px !important;
        overflow: hidden;
    }

    .rotImage.rotImageMid img {
        margin-left: -90px !important
    }

    .rotImage.rotImageLeft img {}
}

/* end 1012 */

@media screen and (max-width: 740px) {
    #kh_n00WC {
        width: 100% !important;
        margin: 20px 0 !important;
        padding: 0 !important;
    }

    .kh-accordion {
        padding: 30px;
    }

    .wcBigVid {
        padding-bottom: 1px !important;
    }

    .wcBigVid .video img {
        width: 100%;
    }

    .wcBigVid p {
        text-align: center;
    }

    .wcBigVidIn {

        margin-top: 30px;
    }

    .wcBigVidIn iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
    }

    #kh_n00WC .wcBigVid {
        width: 100%;
        padding: 30px 30px 0 30px;
    }

    .wcBigVid .video img {
        width: 100%;
    }

    .wcBigVid p {
        text-align: center;
    }

    div.catAggContainer {
        width: 100%;
        padding-top: 30px !important;
    }

    #kh_n00WC ul.catAggList li {
        width: 50% !important
    }

    #catAge {
        width: 2px;
    }

    div.catAggContainer {
        padding: 40px 30px 15px 30px;
    }

    div.kh-circles {
        width: 100%;

    }

    #kh_n00WC div.kh-gallery {
        width: 100%;
        height: 290px;
    }

    ul.kh-g-content li a,
    ul.kh-g-content li a:link,
    ul.kh-g-content li a:visited {
        width: 150px;

    }

    .heromaincontainer {
        width: 100%;
    }

    div.relatedTopicsContainer {
        width: 100%;
        height: 189px;
    }

    #kh_n00WC div.relatedTopics h2 {
        margin-left: 30px;
        margin-top: -8px;
    }

    .relatedTopics button {
        bottom: 42px
    }

    .relatedTopicsList {
        margin-left: -15px !important;
    }

    div.relatedTopicsContainer.higher {
        height: 233px;
    }

    div.relatedTopicsContainer.higher .relatedTopics button {
        bottom: 56px;
    }

    ul.relatedTopicsList li:first-child {
        margin-left: 45px !important;
    }

    #container {
        padding: 0;
    }

    ul.rotator {
        width: 100%;
        margin: 0 auto;
    }

    div.rotatorContainer {
        width: 100%;
    }

    ul.rotator li {
        width: 100%;
        overflow: hidden;
        height: 584px;
    }

    .rotImage {
        width: 100% !important;
        margin-top: -5px;
    }

    .rotImage img {
        transform: scale(1) !important;
    }

    .rotText {
        position: absolute;
        top: 300px;
        width: 100%;
        // background: url(/ws/images//n00/mobile-blue.png);
        background-size: 150%;
        background-repeat: no-repeat;
        background-position: -200px -6px;
        height: 380px !important;
        padding: 95px 30px 20px 30px !important;
    }

    .rotator.rotGray .rotText {
        position: absolute;
        top: 300px;
        width: 100%;
        // background: url(/ws/images//n00/mobile-gray.png);
        background-size: 150%;
        background-repeat: no-repeat;
        background-position: -200px -6px;
        height: 380px !important;
        padding: 95px 30px 20px 30px !important;
    }

    #kh_n00WC .rotText p,
    #kh_n00WC .rotText h2 {
        width: 100%;
        margin: 0 auto;
    }

    #kh_n00WC .rotText h2 {
        margin-bottom: 30px;
    }

    button.rotPlayPause {
        content: '';
        position: absolute;
        right: 50%;
        margin-right: -35px;
        top: 286px;
        width: 70px;
        height: 70px;
        border-radius: 100%;
        text-align: center;
        padding-top: 7px;
        padding-left: 5px;
        box-sizing: border-box;
        font-size: 25px;
        cursor: pointer;
    }

    button.rotPlayPause.rotatorPaused {
        // background: white url(/ws/images//wc/slide-play-btn.svg) no-repeat;
        background-size: 60px;
        background-position: center;
    }

    button.rotPlayPause.rotatorPlaying {
        // background: white url(/ws/images//wc/slide-pause-btn.svg) no-repeat;
        background-size: 60px;
        background-position: center;
    }

    .rotImage.rotImageMid {
        overflow: hidden;
        width: 100% !important;
        margin-top: -14px;
    }

    .rotImage img {
        transform: scale(0.95) !important;
        position: absolute;
        width: 900px;
    }

    .rotImage.rotImageMid img {
        margin-top: -25px !important;
        left: 50%;
        margin-left: -450px !important;
    }

    .rotImage.rotImageLeft img {
        margin-top: -35px !important;
        margin-left: -24px !important;
    }

    #kh_n00WC .wc3Tiles {
        columns: 1;
        margin: 0px !important;
        display: block;
        column-gap: 0px;
        padding: 0px !important;
    }

    #kh_n00WC ul.wc3Tiles.wc2Tiles {
        columns: 2;
        width: 100% !important;
        column-gap: 30px;
        margin-left: 0px;
        margin-right: 0px !important;
        margin-bottom: 0px;
    }

    #kh_n00WC ul.wc3Tiles.wc2Tiles li p {
        width: 100% !important;
        display: block;
    }

    #kh_n00WC ul.wc3Tiles.wc2Tiles li {
        width: 100% !important;

        margin: 0;
        padding: 0;
    }

    #kh_n00WC ul.wc3Tiles.wc2Tiles li img {
        width: 100% !important;
    }

    ul.wc3Tiles li,
    ul.wc3Tiles li p,
    ul.wc3Tiles li img {}

    #kh_n00WC ul.wc3Tiles li {
        height: auto;
        padding-bottom: 30px;

    }

    #kh_n00WC ul.wc3Tiles {
        columns: 1;
        width: 100% !important;
        max-width: 100%;
        display: block;
        column-gap: 0px;
        margin-top: 0;
        margin-bottom: 0;
    }

    #kh_n00WC ul.wc3Tiles li:nth-child(2) {
        padding-top: 0;
        padding-bottom: 0;
    }

    ul.wc3Tiles li,
    ul.wc3Tiles li p,
    ul.wc3Tiles li img {
        width: 100% !important;
    }

    #kh_n00WC ul.wc3Tiles li {
        height: auto;
        padding: 30px;
    }

}

/* end 740 */

@media screen and (max-width: 720px) {
    ul.rotator li {
        height: 574px;
    }

    .rotImage img {
        transform: scale(.925) !important;
    }

    .rotImage.rotImageMid img {
        margin-top: -30px !important;

    }

    .rotImage.rotImageLeft img {
        margin-top: -38px !important;
        margin-left: -36px !important;
    }

    .rotText,
    .rotator.rotGray .rotText {
        top: 290px;
    }

    button.rotPlayPause {
        top: 270px;
    }
}

/* end 720 */

@media screen and (max-width: 700px) {
    .heromaincontainer {
        height: auto;
    }

    #kh_n00WC img.responsivedesktop {
        display: none;
    }

    #kh_n00WC img.responsivemobile {
        display: block;
        width: 100%;
        margin-top: -480px
    }

    .herotextouter {
        width: 100% !important;
        padding-left: 30px;
        padding-right: 30px;
        top: 30px !important;
        text-align: left !important;
        left: unset !important;
        right: unset !important;
    }

    .herobutton {
        float: left !important;
    }

    ul.rotator li {
        height: 560px;
    }

    .rotImage img {
        transform: scale(.9005) !important;
    }

    .rotImage.rotImageMid img {
        margin-top: -34px !important;
    }

    .rotImage.rotImageLeft img {
        margin-top: -44px !important;
        margin-left: -46px !important;
    }

    .rotText,
    .rotator.rotGray .rotText {
        top: 286px;
        padding: 85px 30px 20px 30px !important;
        background-size: 150%;
        background-position: -190px -6px;
    }

    button.rotPlayPause {
        top: 273px;
    }
}

/* end 700 */


@media screen and (max-width: 680px) {
    #kh_n00WC img.responsivemobile {
        margin-top: -460px;
    }

    ul.rotator li {
        height: 546px;
    }

    button.rotPlayPause {
        top: 264px;
    }

    .rotText,
    .rotator.rotGray .rotText {
        top: 277px;
        background-position: -180px -6px;
    }

    .rotImage img {
        transform: scale(.87) !important;
    }

    .rotImage.rotImageMid img {
        margin-top: -40px !important;
    }

    .rotImage.rotImageLeft img {
        margin-top: -50px !important;
        margin-left: -60px !important;
        ;
    }
}

/* end 680 */

@media screen and (max-width: 670px) {
    #kh_n00WC .rteWC {
        padding: 30px 0
    }

    #kh_n00WC ul.wc3Tiles.wc2Tiles {
        columns: 1;
        column-gap: 0;
    }

    #kh_n00WC ul.wc3Tiles.wc2Tiles li:first-child {
        margin-bottom: 30px;
    }
}

/* end 670 */

@media screen and (max-width: 660px) {
    #kh_n00WC img.responsivemobile {
        margin-top: -440px;
    }

    ul.rotator li {
        height: 536px;
    }

    .rotImage img {
        transform: scale(.845) !important;
    }

    .rotImage.rotImageMid img {
        margin-top: -46px !important;
    }

    .rotImage.rotImageLeft img {
        margin-top: -56px !important;
        margin-left: -70px !important;
    }

    .rotText,
    .rotator.rotGray .rotText {
        top: 268px;
    }

    button.rotPlayPause {
        top: 256px;
    }
}

/* end 660 */

@media screen and (max-width: 640px) {
    #kh_n00WC img.responsivemobile {
        margin-top: -420px;
    }

    ul.rotator li {
        height: 530px;
    }

    button.rotPlayPause {
        top: 250px;
    }

    .rotText,
    .rotator.rotGray .rotText {
        top: 262px;
        background-position: -170px -6px;
    }

    .rotImage img {
        transform: scale(.82) !important;
    }

    .rotImage.rotImageMid img {
        margin-top: -50px !important;
    }

    .rotImage.rotImageLeft img {
        margin-top: -60px !important;
        margin-left: -82px !important;
    }
}

/* end 640 */

@media screen and (max-width: 620px) {
    #kh_n00WC img.responsivemobile {
        margin-top: -400px;
    }

    ul.rotator li {
        height: 516px;
    }

    button.rotPlayPause {
        top: 240px;
    }

    .rotText,
    .rotator.rotGray .rotText {
        top: 254px;
        background-position: -160px -6px;
        padding-top: 80px !important;
    }

    .rotImage img {
        transform: scale(.79) !important;
    }

    .rotImage.rotImageMid img {
        margin-top: -56px !important;
    }

    .rotImage.rotImageLeft img {
        margin-top: -65px !important;
        margin-left: -95px !important;
    }
}

/* end 620 */

@media screen and (max-width: 600px) {
    #kh_n00WC img.responsivemobile {
        margin-top: -380px;
    }

    ul.rotator li {
        height: 554px
    }

    button.rotPlayPause {
        top: 280px;
    }

    .rotText,
    .rotator.rotGray .rotText {
        top: 294px;
        background-position: -154px -6px;
    }

    .rotImage img {
        transform: scale(.9395) !important;
    }

    .rotImage.rotImageMid img {
        margin-top: -32px !important;
    }

    .rotImage.rotImageLeft img {
        margin-top: -40px !important;
        margin-left: -28px !important;
    }
}

/* end 600 */

@media screen and (max-width: 580px) {
    #kh_n00WC img.responsivemobile {
        margin-top: -360px;
    }

    ul.rotator li {
        height: 540px;
    }

    button.rotPlayPause {
        top: 270px;
        background-size: 110% !important;
    }

    .rotText,
    .rotator.rotGray .rotText {
        top: 284px;
        background-position: -150px -6px;
        padding-top: 76px !important;
    }

    .rotImage img {
        transform: scale(.91) !important;
    }

    .rotImage.rotImageMid img {
        margin-top: -38px !important;
    }

    .rotImage.rotImageLeft img {
        margin-top: -48px !important;
        margin-left: -41px !important;
    }
}

/* end 580 */

@media screen and (max-width: 568px) {
    #kh_n00WC .rteWC.rteWC2col {
        columns: 1;
    }

    #kh_n00WC ul.kh-circles-content>li {
        display: none;
    }

    #kh_n00WC ul.kh-circles-content li.kh-cc-current {
        display: block !important;
        padding-bottom: 0;
    }

    #kh_n00WC div.kh-cc-text {
        padding: 0;
    }

    div.kh-cc-img {
        width: 100%;
        text-align: center;
        padding-bottom: 20px;
    }

    div.kh-cc-img img {
        width: 160px;

    }

    .kh-circles button {
        position: absolute;
        background: white;
        height: 80px;
        -webkit-box-shadow: 0px 0px 15px -6px rgba(0, 0, 0, 0.75) !important;
        -moz-box-shadow: 0px 0px 15px -6px rgba(0, 0, 0, 0.75) !important;
        box-shadow: 0px 0px 15px -6px rgba(0, 0, 0, 0.75) !important;
        cursor: pointer;
        width: 40px;
        /*top: 140px;*/
        display: block;
    }

    .kh-circles button.circlesLeft {
        left: 0;
        border-radius: 0 100px 100px 0;
        // background: #fff url(/ws/images//n00/caretLeft.svg) top left no-repeat;
        background-size: 10px;
        background-position: 30% 50%;
    }

    .kh-circles button.circlesRight {
        right: 0;
        border-radius: 100px 0 0 100px;
        // background: #fff url(/ws/images//n00/caretRight.svg) top left no-repeat;
        background-size: 10px;
        background-position: 70% 50%;
    }
}

/* end 568 */

@media screen and (max-width: 560px) {
    #kh_n00WC img.responsivemobile {
        margin-top: -340px;
    }

    ul.rotator li {
        height: 530px;
    }

    button.rotPlayPause {
        top: 260px;
    }

    .rotText,
    .rotator.rotGray .rotText {
        top: 274px;
        background-position: -120px -6px;
        background-size: 140%;
    }

    .rotImage img {
        transform: scale(.875) !important;
    }

    .rotImage.rotImageMid img {
        margin-top: -44px !important;
    }

    .rotImage.rotImageLeft img {
        margin-top: -54px !important;
        margin-left: -57px !important;
    }
}

/* end 560 */

@media screen and (max-width: 540px) {
    #kh_n00WC ul.catAggList li {
        width: 90% !important;
    }

    ul.catAggList li a,
    ul.catAggList li a:link,
    ul.catAggList li a:hover,
    ul.catAggList li a:visited {
        awidth: 100%;
    }

    #catAge {
        width: 1px;
    }

    #kh_n00WC img.responsivemobile {
        margin-top: -300px;
    }

    ul.rotator li {
        height: 516px;
    }

    button.rotPlayPause {
        top: 242px;
    }

    .rotText,
    .rotator.rotGray .rotText {
        top: 264px;
        background-position: -110px -6px;
        background-size: 140%;
        padding-top: 70px !important;
    }

    .rotImage img {
        transform: scale(.84) !important;
    }

    .rotImage.rotImageMid img {
        margin-top: -51px !important;
    }

    .rotImage.rotImageLeft img {
        margin-top: -61px !important;
        margin-left: -72px !important;
    }

}

/* end 540 */

@media screen and (max-width: 520px) {
    #kh_n00WC img.responsivemobile {
        margin-top: -280px;
    }

    ul.rotator li {
        height: 506px;
    }

    button.rotPlayPause {
        top: 242px;
        width: 60px;
        height: 60px;
        margin-right: -30px;
    }

    .rotText,
    .rotator.rotGray .rotText {
        top: 256px;
        background-position: -80px -6px;
        background-size: 130%;
        padding-top: 66px !important;
    }

    #kh_n00WC .rotText h2 {
        margin-bottom: 20px;
    }

    .rotImage img {
        transform: scale(.81) !important;
    }

    .rotImage.rotImageMid img {
        margin-top: -57px !important;
    }

    .rotImage.rotImageLeft img {
        margin-top: -66px !important;
        margin-left: -86px !important;
    }

}

/* end 520 */

@media screen and (max-width: 500px) {
    #kh_n00WC img.responsivemobile {
        margin-top: -260px;
    }

    ul.rotator li {
        height: 500px;
    }

    button.rotPlayPause {
        top: 230px;
    }

    .rotText,
    .rotator.rotGray .rotText {
        top: 246px;
        background-position: -110px -6px;
        background-size: 140%;
    }

    .rotImage img {
        transform: scale(.775) !important;
    }

    .rotImage.rotImageMid img {
        margin-top: -64px !important;
    }

    .rotImage.rotImageLeft img {
        margin-top: -73px !important;
        margin-left: -102px !important;
    }
}

/* end 500 */

@media screen and (max-width: 480px) {
    .wcBigVid {
        width: 100%;
        padding: 30px 30px 0 30px;
    }

    #kh_n00WC img.responsivemobile {
        margin-top: -240px;
    }

    ul.rotator {
        width: 100%;
    }

    ul.rotator li {
        width: 100%;
    }

    .rotImage {
        width: 100%;
    }

    .rotText {
        padding: 75px 30px 30px 30px !important;
    }

    .rotText p {}

    ul.rotator li {
        height: 492px;
    }

    button.rotPlayPause {
        top: 218px;
    }

    .rotText,
    .rotator.rotGray .rotText {
        top: 236px;
        background-position: -100px -6px;
        background-size: 140%;
    }

    .rotImage img {
        transform: scale(.75) !important;
    }

    .rotImage.rotImageMid img {
        margin-top: -68px !important;
    }

    .rotImage.rotImageLeft img {
        margin-top: -78px !important;
        margin-left: -114px !important;
    }

    ul.wc3Tiles {
        width: 100% !important;
        max-width: 480px;

    }

    ul.wc3Tiles li,
    ul.wc3Tiles li p,
    ul.wc3Tiles li img {
        width: 100% !important;
    }

    ul.wc3Tiles li {
        padding: 30px;
    }

    ul.wc3Tiles li h2 {
        margin-top: 15px;
    }
}

/* end 480 */


@media screen and (max-width: 460px) {
    #kh_n00WC img.responsivemobile {
        margin-top: -220px;
    }

    ul.rotator li {
        height: 472px;
    }

    button.rotPlayPause {
        top: 208px;
        width: 54px;
        height: 54px;
        margin-right: -27px;
    }

    .rotText,
    .rotator.rotGray .rotText {
        top: 226px;
        background-position: -100px -6px;
        background-size: 140%;
        padding-top: 56px !important;
    }

    .rotImage img {
        transform: scale(.715) !important;
    }

    .rotImage.rotImageMid img {
        margin-top: -75px !important;
    }

    .rotImage.rotImageLeft img {
        margin-top: -84px !important;
        margin-left: -128px !important;
    }
}

/* end 460 */

@media screen and (max-width: 440px) {
    #kh_n00WC img.responsivemobile {
        margin-top: -200px;
    }

    ul.rotator li {
        height: 460px;
    }

    button.rotPlayPause {
        top: 198px;
    }

    .rotText,
    .rotator.rotGray .rotText {
        top: 216px;
        background-position: -90px -6px;
        background-size: 140%;
    }

    .rotImage img {
        transform: scale(.6825) !important;
    }

    .rotImage.rotImageMid img {
        margin-top: -81px !important;
    }

    .rotImage.rotImageLeft img {
        margin-top: -90px !important;
        margin-left: -144px !important;
    }
}

/* end 440 */

@media screen and (max-width: 420px) {
    #kh_n00WC img.responsivemobile {
        margin-top: -180px;
    }

    ul.rotator li {
        height: 454px;
    }

    button.rotPlayPause {
        top: 186px;
    }

    .rotText,
    .rotator.rotGray .rotText {
        top: 206px;
        background-position: -90px -6px;
        background-size: 140%;
    }

    .rotImage img {
        transform: scale(.65) !important;
    }

    .rotImage.rotImageMid img {
        margin-top: -87px !important;
    }

    .rotImage.rotImageLeft img {
        margin-top: -96px !important;
        margin-left: -158px !important;
    }
}

/* end 420 */


@media screen and (max-width: 410px) {
    #kh_n00WC img.responsivemobile {
        margin-top: -160px;
    }

    .herotextouter {
        padding-right: 15px;
    }
}

/* end 410 */

@media screen and (max-width: 400px) {
    ul.rotator li {
        height: 440px;
    }

    button.rotPlayPause {
        top: 176px;
    }

    .rotText,
    .rotator.rotGray .rotText {
        top: 196px;
        background-position: -90px -6px;
        background-size: 140%;
        padding-top: 54px !important;
    }

    #kh_n00WC .rotText h2 {
        font-size: 26px
    }

    .rotImage img {
        transform: scale(.6175) !important;
    }

    .rotImage.rotImageMid img {
        margin-top: -93px !important;
    }

    .rotImage.rotImageLeft img {
        margin-top: -103px !important;
        margin-left: -172px !important;
    }
}

/* end 400 */

@media screen and (max-width: 390px) {
    #kh_n00WC img.responsivemobile {
        margin-top: -140px;
    }
}

/* end 390 */

@media screen and (max-width: 380px) {
    #kh_n00WC .rteWC {
        padding: 20px;
    }

    .wcBigVid {
        padding: 20px;
    }

    #kh_n00WC .wcBigVid {
        padding: 20px !important;

    }

    .wcBigVid .video img {
        width: 100%;
    }

    .wcBigVidIn {
        margin: 20px auto 0px auto;
    }

    div.catAggContainer h2 {
        margin: 0 0 14px 0;
    }

    div.catAggContainer {
        padding: 20px 20px 4px 20px !important;
    }

    div.kh-circles {
        padding: 20px;
    }

    .herotextouter {
        padding-left: 20px;
        padding-right: 20px;
        top: 20px !important;
    }

    div.relatedTopicsContainer {
        padding-top: 20px;
        padding-bottom: 0 !important;
        height: 180px;
    }

    #kh_n00WC div.relatedTopics h2 {
        margin: 0px 0 0 0;
        width: 100%;
        padding: 0px 20px 20px 20px;
        line-height: 1.125 !important;

    }

    .relatedTopics button {
        bottom: 40px;
    }

    ul.relatedTopicsList li:first-child {
        margin-left: 35px !important;
    }


    div.relatedTopicsContainer.higher {
        height: 210px;
    }

    #kh_n00WC div.relatedTopicsContainer.higher h2 {
        line-height: 1.35;
        margin-top: 0px
    }

    div.relatedTopicsContainer.higher .relatedTopics button {
        bottom: 40px;
    }

    ul.rotator li {
        height: 410px;
    }

    button.rotPlayPause {
        top: 166px;
    }

    .rotText,
    .rotator.rotGray .rotText {
        top: 186px;
        background-position: -100px -6px;
        background-size: 150%;
        padding-top: 46px !important;
        padding-left: 15px !important;
        padding-right: 15px !important;
    }

    .rotText p,
    .rotText a {
        font-size: 15px;
    }

    #kh_n00WC .rotText h2 {
        font-size: 25px;
    }

    .rotImage img {
        transform: scale(.584) !important;
    }

    .rotImage.rotImageMid img {
        margin-top: -100px !important;
    }

    .rotImage.rotImageLeft img {
        margin-top: -109px !important;
        margin-left: -188px !important;
    }

    ul.wc3Tiles li {
        padding: 20px;
    }

    ul.wc3Tiles.wc2Tiles {
        width: 100% !important;
        padding: 20px;
    }
}

/* end 380 */

@media screen and (max-width: 370px) {
    #kh_n00WC img.responsivemobile {
        margin-top: -120px;
    }
}

/* end 370 */

@media screen and (max-width: 360px) {
    #kh_n00WC img.responsivemobile {
        margin-top: -100px;
    }

    button.rotPlayPause {
        top: 156px;
    }

    .rotText,
    .rotator.rotGray .rotText {
        top: 174px;
        background-position: -90px -6px;
        background-size: 150%;
    }

    #kh_n00WC .rotText h2 {
        font-size: 24px;
    }

    .rotImage img {
        transform: scale(.555) !important;
    }

    .rotImage.rotImageMid img {
        margin-top: -106px !important;
    }

    .rotImage.rotImageLeft img {
        margin-top: -115px !important;
        margin-left: -200px !important;
    }
}

/* end 360 */

@media screen and (max-width: 350px) {
    #kh_n00WC img.responsivemobile {
        margin-top: -80px;
    }
}

/* end 350 */

@media screen and (max-width: 340px) {
    body {}

    button.rotPlayPause {
        top: 150px;
        width: 50px;
        height: 50px;
        margin-right: -25px;
    }

    .rotText,
    .rotator.rotGray .rotText {
        top: 168px;
        background-position: -90px -6px;
        background-size: 150%;
    }

    #kh_n00WC .rotText h2 {
        font-size: 23px;
    }

    .rotImage img {
        transform: scale(.524) !important;
    }

    .rotImage.rotImageMid img {
        margin-top: -112px !important;
    }

    .rotImage.rotImageLeft img {
        margin-top: -121px !important;
        margin-left: -214px !important;
    }
}

/* end 340 */

@media screen and (max-width: 330px) {
    #kh_n00WC img.responsivemobile {
        margin-top: -60px;
    }
}

/* end 330 */

@media screen and (max-width: 320px) {

    ul.rotator li {
        height: 390px;
    }

    button.rotPlayPause {
        top: 140px;
        width: 50px;
        height: 50px;
        margin-right: -25px;
    }

    .rotText,
    .rotator.rotGray .rotText {
        top: 156px;
        background-position: -90px -6px;
        background-size: 150%;
    }

    .rotImage img {
        transform: scale(.4875) !important;
    }

    .rotImage.rotImageMid img {
        margin-top: -118px !important;
    }

    .rotImage.rotImageLeft img {
        margin-top: -127px !important;
        margin-left: -231px !important;
    }
}

/* end 320 */