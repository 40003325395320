@import 'wc2021';
@import 'wc2021_MQ';
@import 'newIn2021';

#KidsHealthWrapper {
  fieldset {
    border: none;
    padding: 0;
  }

  #RelatedArticles {
    .kh-tab-header {
      float: left;
      padding: 1em 3em;

      &.kh-inactive {
        cursor: pointer;
      }
    }

    .kh-tab-content {

      &.kh-active {
        border: 1px solid #eee;
        clear: both;
        padding: 1em;
      }

      &.kh-hide {
        display: none;
      }
    }
  }
}

#KidsHealthLoading, #KidsHealthCategoryLoading {
  margin: 1em;
  text-align: center;
}

#KidsHealthFooter {
  margin-top: 3em;

  .kh-footer-left {
    float: left;
    width: 80%;
  }
  .kh-footer-right {
    float: right;
    text-align: right;
    width: 20%;
  }
}

#KidsHealthPrintWrapper {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  padding: 1% 5%;

  p, li, ol {
    line-height: 150%;
    margin: 0 0 1em;
  }

  #KidsHealthPrintHeader {
    border-bottom: 1px solid #999;
    margin-bottom: 20px;
    padding-bottom: 10px;

    .kh-print-header-left {
      float: left;
      width: 50%;
    }

    .kh-print-header-right {
      float: right;
      text-align: right;
      width: 50%;
    }

  }
}

#kh_experts_nav, .kh-hide {
  display: none;
}

.kh-clear {
  clear: both;
  overflow: auto;
}

.kh-error {
  color: red;
}