#kh_n00WC *,
#khLicMain #mainContentContainer #kh_n00WC * {
    box-sizing: border-box !important;
}

#kh_n00WC img {
    max-width: unset;
}

#kh_n00WC {
    width: 900px;
    margin: 0 auto;
    text-align: left;
    margin-top: 45px;
    margin-bottom: 45px;
}

#kh_n00WC button {
    border: 0 none;
    padding: 0;
}

#kh_n00WC ul {
    margin: 0;
    padding: 0;
}

#kh_n00WC img {
    margin: 0;
    padding: 0;
}

#kh_n00WC ul li {
    list-style-type: none;
    padding: 0;
}

#kh_n00WC h2,
#kh_n00WC h3 {
    padding: 0;
    margin: 0;
    font-weight: normal !important;
    line-height: 1.25;
}

#kh_n00WC p {
    font-size: 14px;
    line-height: 1.5;
    padding: 0;
}

#khLicMain #khContentHeading>h1 {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    overflow: hidden;
    width: 0;
    height: 0;
    line-height: 0;
}

/* RTEWC */

#kh_n00WC .rteWC {
    padding: 45px;
}

#kh_n00WC .rteWC :last-child {
    margin-bottom: 0;
    padding-bottom: 0;
}

#kh_n00WC .rteWC.rteWC2col {
    columns: 2;
    column-gap: 45px;
}




/* Accordion */

.kh-accordion {
    padding: 45px;
    margin: 0 !important;
}

#kh_n00WC .kh-accordion h2 {
    font-weight: normal;
    font-size: 29.3px;
    padding: 0;
    margin: 0 0 10px 0;
    color: #036;
}

#kh_n00WC .kh-accordion ul {
    list-style-type: none;
    /* margin-left: -40px;
    margin-top: -0px; */
}

#kh_n00WC .kh-accordion ul li {
    border-bottom: 1px solid #c8c8c8;
    padding: 10px 10px 10px 0;
    font-size: 14px;
    margin: 0 !important;
  
}

#kh_n00WC .kh-accordion ul li p,
#kh_n00WC .kh-accordion ul li h3 {
    margin-bottom: 0;
    margin-top: 14px;
}

#kh_n00WC .kh-accordion ul li button.accButt {
    background: transparent;
    font-size: 22px;
    cursor: pointer;
    width: 100%;
    text-align: left;
    position: relative;
    padding-right: 20px;
    padding-left: 0;
    color: black;
    font-weight: normal;
}

.kh-accordion ul li button.accButt:after {
    content: '';
    background-image: url(data:image/svg+xml;base64,PHN2ZyAKIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIgogd2lkdGg9IjQ4cHgiIGhlaWdodD0iOTNweCI+CjxkZWZzPgo8ZmlsdGVyIGlkPSJGaWx0ZXJfMCI+CiAgICA8ZmVGbG9vZCBmbG9vZC1jb2xvcj0icmdiKDExNywgMTE3LCAxMTcpIiBmbG9vZC1vcGFjaXR5PSIxIiByZXN1bHQ9ImZsb29kT3V0IiAvPgogICAgPGZlQ29tcG9zaXRlIG9wZXJhdG9yPSJhdG9wIiBpbj0iZmxvb2RPdXQiIGluMj0iU291cmNlR3JhcGhpYyIgcmVzdWx0PSJjb21wT3V0IiAvPgogICAgPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbj0iY29tcE91dCIgaW4yPSJTb3VyY2VHcmFwaGljIiAvPgo8L2ZpbHRlcj4KCjwvZGVmcz4KPGcgZmlsdGVyPSJ1cmwoI0ZpbHRlcl8wKSI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgIGZpbGw9InJnYigxNDIsIDE0MiwgMTQyKSIKIGQ9Ik05LjIxOSwyLjAwMCBDOS40NDUsMS44MDggNDUuOTY0LDQzLjA0NSA0Ni4yMTksNDMuMDAwIEM0OC40MDQsNDUuMjg0IDQ4LjE2Miw0Ny4wNjYgNDYuMjE5LDQ5LjAwMCBDNDQuMzgzLDUxLjE3MCA4LjI1MSw5MC45MjEgOC4yMTksOTEuMDAwIEM0LjAzMCw5NS4xODggLTEuNTAxLDg4LjcyMCAyLjIxOSw4NS4wMDAgQzIuMDU5LDg0LjkzNCAzNy4yMTksNDYuMDAwIDM3LjIxOSw0Ni4wMDAgQzM3LjIxOSw0Ni4wMDAgMi4xNjQsNy45ODEgMi4yMTksOC4wMDAgQy0yLjM4NywzLjM5NSA0LjI2MiwtMi45NTcgOS4yMTksMi4wMDAgWiIvPgo8L2c+Cjwvc3ZnPg==);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: contain;
    width: 15px;
    height: 15px;
    display: inline-block;
    position: absolute;
    right: -8px;
    top: 8px;
    transform: rotate(90deg);
}

.kh-accordion ul li button.accButt.accExpanded:after {
    transform: rotate(-90deg);
    top: 2px;
}

#kh_n00WC .kh-accordion ul li .accDrop {
    margin-top: -12px;
    padding-right: 30px;
    line-height: 1.5;
}

#kh_n00WC .kh-accordion-all-link a {
    color: #3f70b1;
    padding-top: 12px;
    display: block;
}

/* end accordion */

/* Start WCBig Vid */

#kh_n00WC .wcBigVid {
    width: 900px;
    margin: 0px auto;
    color: white;
    padding: 40px 120px 20px 120px !important;
    text-align: center;
    font-family: arial, helvetica, sans-serif;
    list-style-type: none;
}


.wcBigVid .video {
    text-align: Center;
}

.wcBigVid .video img {
    width: 600px;
    margin: 45px auto 45px auto;

}

#kh_n00WC .wcBigVid.theme1 {
    background: #f0f6fa;
    color: #000;
}

#kh_n00WC .wcBigVid.theme2 {
    background: #3f70b1;
    color: #fff;
}

#kh_n00WC .wcBigVid.theme2 a {
    color: #fff;
}


.wcBigVidIn {
    position: relative;
    overflow: hidden;
    padding-bottom: 56.25%;
    padding-top: 0;
    top: 0;
    margin: 25px auto;
    height: 100%;
}

.wcBigVidIn iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100% !important;
}


#kh_n00WC .wcBigVid h2 {
    font-size: 29.3px;
    font-weight: normal;
    margin-bottom: 10px;
    text-align: center;
}

#kh_n00WC .wcBigVid p {
    font-size: 14px;
}

ul.wcBigVid li div.wcBigVidIn div.khad-container button.khad-button {
    position: absolute;
    top: 0px;
    z-index: 1000;
    height: 22px;
    width: 40px;
    // background: url(/images/n00/icons/ad_icon.png) 3px 4px no-repeat;
    background-size: 32px;
    cursor: pointer;
}

ul.wcBigVid li div.wcBigVidIn div.khad-container[ad-enabled='true'] button.khad-button {
    // background: url(/images/n00/icons/ad_icon_on.png) 3px 4px no-repeat;
    background-size: 32px;
}

/* end wc big vid */

/* cat Agg */
li.catNap {
    /* text-indent: -15000px;
     position: absolute;
     overflow: hidden;
     width: 0px !important;
     height: 0px !important;
     font-size: 0px !important;*/
    display: none !important;
}

div.catAggContainer {
    background: #f3f3f4;
    width: 900px;
    margin: 0px auto 0px auto;
    padding: 39px 45px 20px 45px;
    position: relative;
}

div.catAggContainer.khComponentTitleHiderSR .catAgg h2 {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    overflow: hidden;
    width: 0;
    height: 0;
    line-height: 0;
}

div.catAgg {}

#kh_n00WC div.catAgg h2 {
    font-size: 29.3px;
    font-weight: normal;
    color: #036;
    padding: 0;
    line-height: 1.25;
    margin-bottom: 25px;
    margin-top: 0;
}

#kh_n00WC ul.catAggList {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: hidden;
    margin-bottom: 10px;
    list-style-type: none;
    padding: 0;
}

#kh_n00WC ul.catAggList li {
    width: 25% !important;
    min-height: 30px;
    font-size: 14px;
    line-height: 1.25;
    margin-bottom: 10px;
    box-sizing: border-box;
}

ul.catAggList li a,
ul.catAggList li a:link,
ul.catAggList li a:hover,
ul.catAggList li a:visited {
    text-decoration: none;
    display: block;
    color: #036;
    width: 90%;
}

ul.catAggList li a:hover {
    text-decoration: underline;
}

#kh_n00WC li#catAggMore {
    width: 0px !important;
}

#kh_n00WC #catAggMore button {
    cursor: pointer;
    position: absolute;
    left: 50%;
    width: 150px;
    height: 40px;
    background: #fff;
    margin-left: -75px;
    bottom: -20px;
    border-radius: 8px;
    border: 1px solid #ccc;
    text-align: center;
    font-size: 16px;
    color: #036;
    z-index: 100;
}

#kh_n00WC #catAggMore button:hover {
    background: #3f70b1;
    color: #fff;
    text-decoration: none;
    border-color: #3f70b1;
}

#kh_n00WC button.catAggMoreButt:after {
    width: 20px;
    height: 20px;
    padding-left: 5px;
    content: '+';
}

#kh_n00WC button.catAggLessButt:after {
    width: 20px;
    height: 20px;
    padding-left: 5px;
    content: '-';
}

#catAge {
    width: 4px;
}


/* THEMES / COLOR SCHEMES */

/* Theme 1 */

.catAggContainer.theme1 {
    background: #f3f3f4;
}

.catAggContainer.theme1 ul.catAggList li a,
.catAggContainer.theme1 ul.catAggList li a:link,
.catAggContainer.theme1 ul.catAggList li a:hover,
.catAggContainer.theme1 ul.catAggList li a:visited {
    color: #036;
}

.catAggContainer.theme1 ul.catAggList li a:hover {}

.catAggContainer.theme1 div.catAgg h2 {
    color: #036;
}


.catAggContainer.theme1 #catAggMore button {
    background: #fff;
    color: #036;
    border-color: #ccc;
}

.catAggContainer.theme1 #catAggMore button:hover {
    background: #3f70b1;
    color: #fff;
    border-color: #3f70b1;
}

/* Theme 2 */

.catAggContainer.theme2 {
    background: #ecf3f8;
}

.catAggContainer.theme2 ul.catAggList li a,
.catAggContainer.theme2 ul.catAggList li a:link,
.catAggContainer.theme2 ul.catAggList li a:hover,
.catAggContainer.theme2 ul.catAggList li a:visited {
    color: #3f70b1;
}

.catAggContainer.theme2 ul.catAggList li a:hover {}

.catAggContainer.theme2 div.catAgg h2 {
    color: #036;
}


.catAggContainer.theme2 #catAggMore button {
    background: #fff;
    color: #3f70b1;
    border-color: #3f70b1;
}

.catAggContainer.theme2 #catAggMore button:hover {
    background: #3f70b1;
    color: #fff;
    border-color: #3f70b1;
}

/* Theme 3 */

.catAggContainer.theme3 {
    background: #9e1f63;
}

.catAggContainer.theme3 ul.catAggList li a,
.catAggContainer.theme3 ul.catAggList li a:link,
.catAggContainer.theme3 ul.catAggList li a:hover,
.catAggContainer.theme3 ul.catAggList li a:visited {
    color: #fff;
}

.catAggContainer.theme3 ul.catAggList li a:hover {}

.catAggContainer.theme3 div.catAgg h2 {
    color: #f0f6fa;
}


.catAggContainer.theme3 #catAggMore button {
    background: #fff;
    color: #9e1f6a;
    border-color: #9e1f63;
}

.catAggContainer.theme3 #catAggMore button:hover {
    background: #fff;
    color: #036;
    border-color: #9e1f6a;
}



/* End Themes */

/* end catagg */

/* Circles */

div.kh-circles {
    position: relative;
    overflow: hidden;
    padding: 45px;
    width: 900px;
}

div.kh-circles.theme1 {
    background: #f3f3f4;
}

div.kh-circles.theme2 {
    background: #f0f6fa;
}

div.kh-circles-heading {
    text-align: center;
    padding-bottom: 30px;
}

#kh_n00WC div.kh-circles-heading h2 {
    font-size: 29.3px;
    color: #036;
    margin: 0;
    font-weight: bold !important;
    padding: 0;
}

#kh_n00WC div.kh-circles-heading h2 span.kh-ch-h2NotBold {
    font-weight: normal !important;
}

div.kh-circles-heading p {
    font-size: 14px;
    margin: 10px 0 0 0;
    padding: 0;
}

.kh-circles button {
    display: none;
}

ul.kh-circles-content {
    list-style-type: none;
    padding: 0;
    margin: 0;

}

#kh_n00WC ul.kh-circles-content li {
    display: flex;
    justify-content: flex-start;
    padding-bottom: 30px;
}

#kh_n00WC ul.kh-circles-content li:last-child {
    padding-bottom: 0;
}

div.kh-cc-img {
    width: 160px;
}

div.kh-cc-img img {
    width: 160px;
    border-radius: 100%;
}

#kh_n00WC div.kh-cc-text {
    display: block;
    width: 100%;
    padding-left: 30px;
}

#kh_n00WC div.kh-cc-text h3,
#kh_n00WC div.kh-cc-text h2,
#kh_n00WC div.kh-cc-text h1,
#kh_n00WC div.kh-cc-text h6,
#kh_n00WC div.kh-cc-text h5,
div.kh-cc-text h4 {
    color: #036;
    font-size: 22px;
    margin: 5px 0 20px 0;
    font-weight: normal;
    line-height: 1.25;
}

#kh_n00WC div.kh-cc-text p {
    font-size: 14px;
    margin: 10px 0;
}

#kh_n00WC div.kh-cc-text p a,
#kh_n00WC div.kh-cc-text ul li a {
    color: #3f70b1;
    text-decoration: underline;
}

#kh_n00WC div.kh-cc-text p a:hover,
#kh_n00WC div.kh-cc-text ul li a:hover {
    text-decoration: none;
}

#kh_n00WC div.kh-cc-text ul li {
    font-size: 14px;
    padding-bottom: 14px;
}

#kh_n00WC div.kh-cc-text ul li:before {
    content: '\00b7\FE0E';
    width: 14px;
    height: 100%;
    padding-top: 0px;
    font-size: 1rem;
}



/* end Circles */

/* Gallery */

#kh_n00WC div.kh-gallery {
    width: 900px;
    padding: 45px;
    margin: 0;
    height: 350px;
    overflow: hidden;
    position: relative;
}

div.kh-gallery.theme1 {
    background: #f3f3f4;
}

div.kh-gallery.theme2 {
    background: #f0f6fa
}

#kh_n00WC div.kh-g-inner {
    height: 210px;
    padding-bottom: 25px !important
}

#kh_n00WC .kh-g-inner h2 {
    color: #036;
    font-weight: normal;
    font-size: 29.3px;
    padding: 0;
    margin: -9px 0 25px 0;
    line-height: 1.25;
}

#kh_n00WC ul.kh-g-content {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
    height: 400px;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
}

#kh_n00WC ul.kh-g-content li {
    display: inline-block !important;
    margin-right: 30px;
    margin-left: 0;
    padding: 0;
    white-space: break-spaces;
    height: 100px;
    overflow: hidden;
}

#kh_n00WC div.kh-gallery ul.kh-g-content li:last-child {
    margin-right: 0;
}

#kh_n00WC ul.kh-g-content li a,
#kh_n00WC ul.kh-g-content li a:link,
#kh_n00WC ul.kh-g-content li a:visited {
    display: block;
    width: 175px;
    background: white;
    font-size: 14px;
    text-decoration: none;
    color: #3f70b1;
}

ul.kh-g-content li a:hover {}

#kh_n00WC ul.kh-g-content li img {
    width: 100%;
}

#kh_n00WC div.kh-gallery ul.kh-g-content li a div.kh-gc-text {
    position: relative;
    height: 60px;
}

#kh_n00WC div.kh-gallery ul.kh-g-content li a div.kh-gc-text p {
    padding: 10px;
    text-align: center;
    width: 100%;
    min-height: 80px;
    overflow: hidden;
    margin: 0;
    top: 0;
    position: absolute;
    display: block;
}

div.kh-gallery ul.kh-g-content li a div.kh-gc-text p:hover {
    text-decoration: underline;
}

/* buttons */

#kh_n00WC .kh-gallery button {
    position: absolute;
    background: white;
    height: 80px;
    -webkit-box-shadow: 0px 0px 15px -6px rgba(0, 0, 0, 0.75) !important;
    -moz-box-shadow: 0px 0px 15px -6px rgba(0, 0, 0, 0.75) !important;
    box-shadow: 0px 0px 15px -6px rgba(0, 0, 0, 0.75) !important;
    cursor: pointer;
    width: 40px;
    bottom: 134px;
    z-index: 100;
}

#kh_n00WC .kh-gallery button#galPrev {
    left: 0;
    border-radius: 0 100px 100px 0;
    background: #fff url(data:image/svg+xml;base64,PHN2ZyAKIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIgogd2lkdGg9IjQ4cHgiIGhlaWdodD0iOTNweCI+CjxkZWZzPgo8ZmlsdGVyIGlkPSJGaWx0ZXJfMCI+CiAgICA8ZmVGbG9vZCBmbG9vZC1jb2xvcj0icmdiKDExNywgMTE3LCAxMTcpIiBmbG9vZC1vcGFjaXR5PSIxIiByZXN1bHQ9ImZsb29kT3V0IiAvPgogICAgPGZlQ29tcG9zaXRlIG9wZXJhdG9yPSJhdG9wIiBpbj0iZmxvb2RPdXQiIGluMj0iU291cmNlR3JhcGhpYyIgcmVzdWx0PSJjb21wT3V0IiAvPgogICAgPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbj0iY29tcE91dCIgaW4yPSJTb3VyY2VHcmFwaGljIiAvPgo8L2ZpbHRlcj4KCjwvZGVmcz4KPGcgZmlsdGVyPSJ1cmwoI0ZpbHRlcl8wKSI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgIGZpbGw9InJnYigxNDIsIDE0MiwgMTQyKSIKIGQ9Ik0zOC43ODEsOTEuMDAwIEMzOC41NTUsOTEuMTkyIDIuMDM2LDQ5Ljk1NSAxLjc4MSw1MC4wMDAgQy0wLjQwNCw0Ny43MTYgLTAuMTYyLDQ1LjkzNCAxLjc4MSw0NC4wMDAgQzMuNjE3LDQxLjgzMCAzOS43NDksMi4wNzkgMzkuNzgxLDIuMDAwIEM0My45NzAsLTIuMTg4IDQ5LjUwMSw0LjI4MCA0NS43ODEsOC4wMDAgQzQ1Ljk0MSw4LjA2NiAxMC43ODEsNDcuMDAwIDEwLjc4MSw0Ny4wMDAgQzEwLjc4MSw0Ny4wMDAgNDUuODM2LDg1LjAxOSA0NS43ODEsODUuMDAwIEM1MC4zODcsODkuNjA1IDQzLjczOCw5NS45NTcgMzguNzgxLDkxLjAwMCBaIi8+CjwvZz4KPC9zdmc+) top left no-repeat;
    background-size: 10px;
    background-position: 30% 50%;
}

#kh_n00WC .kh-gallery button#galNext {
    right: 0;
    border-radius: 100px 0 0 100px;
    background: #fff url(data:image/svg+xml;base64,PHN2ZyAKIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIgogd2lkdGg9IjQ4cHgiIGhlaWdodD0iOTNweCI+CjxkZWZzPgo8ZmlsdGVyIGlkPSJGaWx0ZXJfMCI+CiAgICA8ZmVGbG9vZCBmbG9vZC1jb2xvcj0icmdiKDExNywgMTE3LCAxMTcpIiBmbG9vZC1vcGFjaXR5PSIxIiByZXN1bHQ9ImZsb29kT3V0IiAvPgogICAgPGZlQ29tcG9zaXRlIG9wZXJhdG9yPSJhdG9wIiBpbj0iZmxvb2RPdXQiIGluMj0iU291cmNlR3JhcGhpYyIgcmVzdWx0PSJjb21wT3V0IiAvPgogICAgPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbj0iY29tcE91dCIgaW4yPSJTb3VyY2VHcmFwaGljIiAvPgo8L2ZpbHRlcj4KCjwvZGVmcz4KPGcgZmlsdGVyPSJ1cmwoI0ZpbHRlcl8wKSI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgIGZpbGw9InJnYigxNDIsIDE0MiwgMTQyKSIKIGQ9Ik05LjIxOSwyLjAwMCBDOS40NDUsMS44MDggNDUuOTY0LDQzLjA0NSA0Ni4yMTksNDMuMDAwIEM0OC40MDQsNDUuMjg0IDQ4LjE2Miw0Ny4wNjYgNDYuMjE5LDQ5LjAwMCBDNDQuMzgzLDUxLjE3MCA4LjI1MSw5MC45MjEgOC4yMTksOTEuMDAwIEM0LjAzMCw5NS4xODggLTEuNTAxLDg4LjcyMCAyLjIxOSw4NS4wMDAgQzIuMDU5LDg0LjkzNCAzNy4yMTksNDYuMDAwIDM3LjIxOSw0Ni4wMDAgQzM3LjIxOSw0Ni4wMDAgMi4xNjQsNy45ODEgMi4yMTksOC4wMDAgQy0yLjM4NywzLjM5NSA0LjI2MiwtMi45NTcgOS4yMTksMi4wMDAgWiIvPgo8L2c+Cjwvc3ZnPg==) top left no-repeat;
    background-size: 10px;
    background-position: 70% 50%;
}

.kh-gallery button#galPrev:hover,
.kh-gallery button#galNext:hover,
.relatedTopics button#rtNext:active {
    background-size: 12px;


}


/* End Gallery */

/* Hero */

/* hero css */


.heromaincontainer {
    width: 900px;
    height: 300px;
    overflow: hidden;
    position: relative;
    z-index: 10;
    font-family: arial, helvetica, sans-serif;
}

#kh_n00WC img.responsivemobile {
    display: none;
}

#kh_n00WC img.responsivedesktop {
    width: 900px;
    z-index: 10;
}

.herotextouter {
    width: 335px;
    display: block;
    position: absolute;
    z-index: 100;
    top: 45px;
}

.herotextouter p {
    font-size: 14px;
}

.herotextouter h2 {
    font-size: 29.3px;
    color: #036;
    margin-bottom: 20px;
}

.herorightalign .herotextouter {
    right: 60px;
    text-align: left;
}

.heroleftalign .herotextouter {
    left: 60px;
    text-align: left;
}

.herobutton {
    display: block;
    width: 160px;
    height: 50px;
    text-align: center;
    text-decoration: none;
    font-size: 18px;
    border-radius: 8px;
    padding-top: 12px;
    margin-top: 24px;
    -webkit-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.32);
    -moz-box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.32);
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.32);
    background: white;
}

.herorightalign .herobutton {
    float: none;
}

/* button colors */

.wfbb .herobutton {
    background: #fff;
    color: #3f70b1;
    border: 1px solid #3f70b1;
}

.obf .herobutton {
    background: #3f70b1;
    color: #fff;
    border: 0 none;
}

.nbf .herobutton {
    background: #036;
    color: #fff;
    border: 0 none;
}

.gf .herobutton {
    background: #03816e;
    color: #fff;
    border: 0 none;
}

.tf .herobutton {
    background: #07808d;
    color: #fff;
    border: 0 none;
}

.pff .herobutton {
    background: #9e1f63;
    color: #fff;
    border: 0 none;
}

.wfgb .herobutton {
    background: #fff;
    color: #03816e;
    border: 1px solid #03816e;
}

.wftb .herobutton {
    background: #fff;
    color: #07808d;
    border: 1px solid #07808d;
}

.wfpfb .herobutton {
    background: #fff;
    color: #9e1f63;
    border: 1px solid #9e1f63;
}


/* End Hero */

/* Related Topics */

div.relatedTopicsContainer {
    width: 900px;
    background: #f9f9f9;
    margin: 0px auto;
    padding: 40px 0;
    position: relative;
    overflow: hidden;
    height: 198px;
}

div.relatedTopicsContainer.khComponentTitleHiderSR .relatedTopics h2 {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    overflow: hidden;
    width: 0;
    height: 0;
    line-height: 0;
}

div.relatedTopicsContainer.khComponentTitleHiderSR {
    height: 168px;
    padding-top: 60px !important;
}

div.relatedTopicsContainer.khComponentTitleHiderSR .relatedTopics ul.relatedTopicsList {}

div.relatedTopics {}

#kh_n00WC div.relatedTopics h2 {
    font-size: 22pt;
    font-weight: normal;
    line-height: 1;
    padding: 0;
    margin: 0 0 20px 45px;
    color: #036;
    width: 90%;
}

ul.relatedTopicsList {
    height: 170px !important;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 0;
}

ul.relatedTopicsList li {
    display: inline;
    padding-bottom: 10px !important;
    margin-right: 7px;
    line-height: 1.25;
}

ul.relatedTopicsList li:first-child {
    margin-left: 45px;
}

ul.relatedTopicsList li:last-child {
    margin-right: 45px;
}

ul.relatedTopicsList li a,
ul.relatedTopicsList li a:link,
ul.relatedTopicsList li a:visited {
    display: inline-block;
    background: #3f70b1;
    color: white;
    text-decoration: none;
    border-radius: 6px;
    padding: 15px;
    font-size: 14px;
    margin-bottom: 10px;
    margin-right: 0px;
    box-sizing: border-box;
    border: 1px solid #3f70b1;
    height: 50px;
}

ul.relatedTopicsList li a:hover {
    background: white;
    color: #3f70b1;
    border: 1px solid #3f70b1;
}

.relatedTopics button {
    position: absolute;
    background: white;
    height: 80px;
    -webkit-box-shadow: 0px 0px 15px -6px rgba(0, 0, 0, 0.75) !important;
    -moz-box-shadow: 0px 0px 15px -6px rgba(0, 0, 0, 0.75) !important;
    box-shadow: 0px 0px 15px -6px rgba(0, 0, 0, 0.75) !important;
    cursor: pointer;
    width: 40px;
    bottom: 44px;
}

.relatedTopics button#rtPrev {
    left: 0;
    border-radius: 0 100px 100px 0;
    background: #fff url(data:image/svg+xml;base64,PHN2ZyAKIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIgogd2lkdGg9IjQ4cHgiIGhlaWdodD0iOTNweCI+CjxkZWZzPgo8ZmlsdGVyIGlkPSJGaWx0ZXJfMCI+CiAgICA8ZmVGbG9vZCBmbG9vZC1jb2xvcj0icmdiKDExNywgMTE3LCAxMTcpIiBmbG9vZC1vcGFjaXR5PSIxIiByZXN1bHQ9ImZsb29kT3V0IiAvPgogICAgPGZlQ29tcG9zaXRlIG9wZXJhdG9yPSJhdG9wIiBpbj0iZmxvb2RPdXQiIGluMj0iU291cmNlR3JhcGhpYyIgcmVzdWx0PSJjb21wT3V0IiAvPgogICAgPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbj0iY29tcE91dCIgaW4yPSJTb3VyY2VHcmFwaGljIiAvPgo8L2ZpbHRlcj4KCjwvZGVmcz4KPGcgZmlsdGVyPSJ1cmwoI0ZpbHRlcl8wKSI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgIGZpbGw9InJnYigxNDIsIDE0MiwgMTQyKSIKIGQ9Ik0zOC43ODEsOTEuMDAwIEMzOC41NTUsOTEuMTkyIDIuMDM2LDQ5Ljk1NSAxLjc4MSw1MC4wMDAgQy0wLjQwNCw0Ny43MTYgLTAuMTYyLDQ1LjkzNCAxLjc4MSw0NC4wMDAgQzMuNjE3LDQxLjgzMCAzOS43NDksMi4wNzkgMzkuNzgxLDIuMDAwIEM0My45NzAsLTIuMTg4IDQ5LjUwMSw0LjI4MCA0NS43ODEsOC4wMDAgQzQ1Ljk0MSw4LjA2NiAxMC43ODEsNDcuMDAwIDEwLjc4MSw0Ny4wMDAgQzEwLjc4MSw0Ny4wMDAgNDUuODM2LDg1LjAxOSA0NS43ODEsODUuMDAwIEM1MC4zODcsODkuNjA1IDQzLjczOCw5NS45NTcgMzguNzgxLDkxLjAwMCBaIi8+CjwvZz4KPC9zdmc+) top left no-repeat;
    background-size: 10px;
    background-position: 30% 50%;
}

.relatedTopics button#rtNext {
    right: 0;
    border-radius: 100px 0 0 100px;
    background: #fff url(data:image/svg+xml;base64,PHN2ZyAKIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIKIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIgogd2lkdGg9IjQ4cHgiIGhlaWdodD0iOTNweCI+CjxkZWZzPgo8ZmlsdGVyIGlkPSJGaWx0ZXJfMCI+CiAgICA8ZmVGbG9vZCBmbG9vZC1jb2xvcj0icmdiKDExNywgMTE3LCAxMTcpIiBmbG9vZC1vcGFjaXR5PSIxIiByZXN1bHQ9ImZsb29kT3V0IiAvPgogICAgPGZlQ29tcG9zaXRlIG9wZXJhdG9yPSJhdG9wIiBpbj0iZmxvb2RPdXQiIGluMj0iU291cmNlR3JhcGhpYyIgcmVzdWx0PSJjb21wT3V0IiAvPgogICAgPGZlQmxlbmQgbW9kZT0ibm9ybWFsIiBpbj0iY29tcE91dCIgaW4yPSJTb3VyY2VHcmFwaGljIiAvPgo8L2ZpbHRlcj4KCjwvZGVmcz4KPGcgZmlsdGVyPSJ1cmwoI0ZpbHRlcl8wKSI+CjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgIGZpbGw9InJnYigxNDIsIDE0MiwgMTQyKSIKIGQ9Ik05LjIxOSwyLjAwMCBDOS40NDUsMS44MDggNDUuOTY0LDQzLjA0NSA0Ni4yMTksNDMuMDAwIEM0OC40MDQsNDUuMjg0IDQ4LjE2Miw0Ny4wNjYgNDYuMjE5LDQ5LjAwMCBDNDQuMzgzLDUxLjE3MCA4LjI1MSw5MC45MjEgOC4yMTksOTEuMDAwIEM0LjAzMCw5NS4xODggLTEuNTAxLDg4LjcyMCAyLjIxOSw4NS4wMDAgQzIuMDU5LDg0LjkzNCAzNy4yMTksNDYuMDAwIDM3LjIxOSw0Ni4wMDAgQzM3LjIxOSw0Ni4wMDAgMi4xNjQsNy45ODEgMi4yMTksOC4wMDAgQy0yLjM4NywzLjM5NSA0LjI2MiwtMi45NTcgOS4yMTksMi4wMDAgWiIvPgo8L2c+Cjwvc3ZnPg==) top left no-repeat;
    background-size: 10px;
    background-position: 70% 50%;
}

.relatedTopics button#rtPrev:hover,
.relatedTopics button#rtNext:hover,
.relatedTopics button#rtNext:active {
    background-size: 12px;


}

.relatedTopicsList li.listBreak a {
    background: red !important;
}


/* THEMES / COLOR SCHEMES */

/* Theme 1 */

div.relatedTopicsContainer.theme1 {
    background: #f3f3f4;
}

div.relatedTopicsContainer.theme1 div.relatedTopics h2 {
    color: #036;
}

div.relatedTopicsContainer.theme1 ul.relatedTopicsList li a,
div.relatedTopicsContainer.theme1 ul.relatedTopicsList li a:link,
div.relatedTopicsContainer.theme1 ul.relatedTopicsList li a:visited {
    background: #3f70b1;
    color: white;
    border: 1px solid #3f70b1;
}

div.relatedTopicsContainer.theme1 ul.relatedTopicsList li a:hover {
    background: white;
    color: #3f70b1;
    border: 1px solid #3f70b1;
}

/* Theme 2 */

div.relatedTopicsContainer.theme2 {
    background: #ecf3f8;
}

div.relatedTopicsContainer.theme2 div.relatedTopics h2 {
    color: #036;
}

div.relatedTopicsContainer.theme2 ul.relatedTopicsList li a,
div.relatedTopicsContainer.theme2 ul.relatedTopicsList li a:link,
div.relatedTopicsContainer.theme2 ul.relatedTopicsList li a:visited {
    background: #3f70b1;
    color: #fff;
    border: 1px solid #3f70b1;
}

div.relatedTopicsContainer.theme2 ul.relatedTopicsList li a:hover {
    background: #fff;
    color: #3f70b1;
    border: 1px solid #3f70b1
}

/* Theme 3 */

div.relatedTopicsContainer.theme3 {
    background: #e5e5e5;
}

div.relatedTopicsContainer.theme3 div.relatedTopics h2 {
    color: #036;
}

div.relatedTopicsContainer.theme3 ul.relatedTopicsList li a,
div.relatedTopicsContainer.theme3 ul.relatedTopicsList li a:link,
div.relatedTopicsContainer.theme3 ul.relatedTopicsList li a:visited {
    background: #fff;
    color: #3f70b1;
    border: 1px solid #3f70b1;
}

div.relatedTopicsContainer.theme3 ul.relatedTopicsList li a:hover {
    background: #3f70b1;
    color: #fff;
    border: 1px solid #3f70b1;
}

/* Theme 4 */

div.relatedTopicsContainer.theme4 {
    background: #fff;
}

div.relatedTopicsContainer.theme4 div.relatedTopics h2 {
    color: #036;
}

div.relatedTopicsContainer.theme4 ul.relatedTopicsList li a,
div.relatedTopicsContainer.theme4 ul.relatedTopicsList li a:link,
div.relatedTopicsContainer.theme4 ul.relatedTopicsList li a:visited {
    background: #3f70b1;
    color: #fff;
    border: 1px solid #3f70b1;
}

div.relatedTopicsContainer.theme4 ul.relatedTopicsList li a:hover {
    background: #fff;
    color: #3f70b1;
    border: 1px solid #3f70b1
}


/* END THEMES */

/* added rules when JS adds 'higher' class to container due to title wrapping */

div.relatedTopicsContainer.higher {
    height: 230px;
}

div.relatedTopicsContainer.higher h2 {
    line-height: 1.25;
    margin-top: -12px
}


/* End Related Topics */

/* Rotator */

* {
    box-sizing: border-box;
}

ul.rotator li {
    display: none;
}

ul.rotator li.slActive {
    display: block;
}

div.rotatorContainer {
    width: 900px;
    margin: 0 auto;
    position: relative;
}

ul.rotator {
    width: 900px;
    position: relative;
    margin: 0 auto;
    padding: 0;
}

.rotator li {
    width: 900px;
    height: 300px;
    list-style-type: none;
    position: relative;
}

.rotImage,
.rotImage img {
    width: 600px;
    max-width: unset;
}

.rotText {
    font-family: arial, helvetica, sans-serif;
    font-weight: normal;
    font-size: 16px;
    width: 340px;
    height: 300px;
    position: absolute;
    top: 0;
    right: 0px;
    padding: 35px 40px 20px 70px !important;
    background-repeat: no-repeat;
    // background: transparent url(/images/n00/bg-blue.png);
    background-size: 120%;
    background-position: -20px -30px;
    text-align: left;
}

.rotator.rotGray .rotText {
    color: black;
    // background: transparent url(/images/n00/bg-gray.png);
    background-size: 120%;
    background-position: -20px -30px;
}

#kh_n00WC .rotator.rotGray .rotText h2 {
    color: #036 !important;
    text-decoration: none;
}

#kh_n00WC .rotator.rotGray .rotText p,
#kh_n00WC .rotator.rotGray .rotText a {
    color: #000;
}

#kh_n00WC .rotator.rotBlue .rotText h2,
#kh_n00WC .rotator.rotBlue .rotText p,
#kh_n00WC .rotator.rotBlue .rotText a {
    color: #fff !important;
    text-decoration: none;
}

.rotator.rotGray .rotText,
.rotator.rotGray .rotText p,
.rotator.rotGray .rotText a {
    color: black;
}

.rotator.rotBlue .rotText,
.rotator.rotBlue .rotText p,
.rotator.rotBlue .rotText a {
    color: #fff;
}

#kh_n00WC .rotator .rotText h2 {
    line-height: 1.25;
    font-size: 22pt;
    margin-bottom: 20px;
    font-weight: bold;
}

.rotText p {
    line-height: 1.25;
}

.rotText,
.rotText a {
    color: white;
}

.rotText a {
    display: block;
    margin-top: 20px !important;
}

.rotText a:hover {
    text-decoration: none;
}

button.rotPlayPause {
    content: '';
    position: absolute;
    right: 300px;
    top: 120px;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    text-align: center;
    padding-top: 7px;
    padding-left: 5px;
    box-sizing: border-box;
    font-size: 25px;
    cursor: pointer;
}

button.rotPlayPause,
button.rotPlayPause.paused {
    // background: white url(/images/n00/icons/play.svg) no-repeat;
    background-size: 60px;
    background-position: center;
}

button.rotPlayPause.playing {
    // background: white url(/images/n00/icons/pause.svg) no-repeat;
    background-size: 60px;
    background-position: center;
}

ul.rotatorPages {
    width: 300px;
    text-align: center;
    padding: 0;
    margin: 10px auto 20px auto;
}

ul.rotatorPages li {
    display: inline;
    padding: 0;
}

ul.rotatorPages li button {
    background: #3f70b1;
    -webkit-appearance: none;
    border: 2px solid #3f70b1;
    border-radius: 100%;
    width: 16px;
    height: 16px;
    cursor: pointer;
    margin: 0 6px 0 6px;
}

ul.rotatorPages li button:active,
ul.rotatorPages li button:hover,
ul.rotatorPages li.active button {
    background: white;
}

.hideOffScreen {
    text-indent: -15000px;
    position: absolute;
}

/* dots */

ul.kh-slider-dots {
    list-style-type: none;
    margin: 12px 0 20px 0 !important;
    text-align: center;
    padding: 0 !important;
}

#kh_n00WC ul.kh-slider-dots li {
    display: inline-block;
    padding: 0 10px 0;
}

ul.kh-slider-dots li button {
    content: '';
    background: #5b89c3;
    width: 16px;
    height: 16px;
    display: block;
    border-radius: 100%;
    border: 1px solid #5b89c3 !important;
    cursor: pointer;
}

ul.kh-slider-dots li button.dotActive {
    background: transparent;
}


/* end rotator */

/* Tiles */

/* Flex box */
/*

.wc3Tiles {
    width: 900px;
    margin-top: 75px;
    display: flex;
    flex-direction: row;

    justify-content: space-around !important;
    background: pink;
    left: 50%;
    position: absolute;
    margin-left: -450px;
    list-style: none;
}

ul.wc3Tiles li, ul.wc3Tiles li img, ul.wc3Tiles li p {
    width: 270px;
    flex: 50%;
    flex-grow: 1;
}

ul.wc3Tiles li {
    padding: 0;
    margin: 0;
    font-size: 1em;
    flex-basis: ;
    align-self: ;
}

*/

/* Columns */
#kh_n00WC .wc3Tiles {
    columns: 3;
    width: 900px;
    dmargin: 45px auto 45px auto;
    column-gap: 45px;
    font-family: arial, helvetica, sans-serif;
    display: flex;
    justify-content: space-between;
}

#kh_n00WC ul.wc3Tiles.wc2Tiles {
    columns: 2;
    width: 900px;
    column-gap: 45px;
    background: transparent;
    padding: 45px;
    margin-top: 0px;
    margin-bottom: 0px;
}

#kh_n00WC ul.wc3Tiles {
    margin: 0;
    padding: 45px 0 45px 0;
}


div.khad-container {
    position: absolute;
    z-index: 100;
}

button.khad-button {
    position: absolute;
    top: 0px;
    z-index: 1000;
    height: 22px;
    width: 40px;
    // background: url(/images/n00/icons/ad_icon.png) 3px 4px no-repeat;
    background-size: 32px;
    cursor: pointer;
}

div.khad-container[ad-enabled='true'] button.khad-button {
    // background: url(/images/n00/icons/ad_icon_on.png) 3px 4px no-repeat;
    background-size: 32px;
}

ul.wc3Tiles li div.tileVideo {
    position: relative;
    overflow: hidden;
    padding-top: 56.25%;
}

ul.wc3Tiles li iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
}


ul.wc3Tiles.wc2Tiles.theme1 {
    background: #f3f3f4;
}

ul.wc3Tiles.wc2Tiles.theme2 {
    background: #fff;
}

ul.wc3Tiles li {
    width: 270px;
    height: auto;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

ul.wc3Tiles.wc2Tiles li,
ul.wc3Tiles.wc2Tiles li img {
    width: 382px;
    /*136 gaps*/
}

#kh_n00WC ul.wc3Tiles li h2 {
    color: #036;
    font-size: 20px;
    font-weight: normal;
    margin-top: 20px;
    margin-bottom: 10px;
}

ul.wc3Tiles li img {
    width: 270px;
}

ul.wc3Tiles li p {
    display: block;
    margin-top: 10px;
    font-size: 14px;
}

ul.wc3Tiles li p a {
    display: inline;
    margin-top: 15px;
    color: #3f70b1
}

ul.wc3Tiles li p a:hover {
    text-decoration: none;
}

/* End Tiles */